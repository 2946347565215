import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

function Header() {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <header className="header">
      <div className="logo">
        {" "}
        <img
          src="https://www.neartekpod.com/hubfs/raw_assets/public/ntp-2.0-main/assets/logo.png"
          className="w-32 p-2  md:w-[196px]"
          alt="Neartekpod Logo"
        />
      </div>
      <div className="user-info">
        {isAuthenticated ? (
          <>
            <span className="text-xs md:text-xl"> Welcome {user.name} </span>

            <button
              className="logout-btn bg-logout p-2 text-xs md:text-lgx text-white"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              Log Out
            </button>
          </>
        ) : (
          <>
            <button className="logout-btn" onClick={() => loginWithRedirect()}>
              Login
            </button>
          </>
        )}
      </div>
    </header>
  );
}

export default Header;
